import React, {useEffect, useRef} from 'react';
import * as d3 from 'd3';
import {OrgChartNode} from './_requests';

interface OrgChartComponentProps {
  data: OrgChartNode;
}

const departmentColors: {[key: string]: string} = {
  Finance: '#4e79a7',
  Operations: '#f28e2c',
  'Sales & Marketing': '#e15759',
  'Human Resources': '#76b7b2',
  'Information Technology': '#59a14f',
};

export const OrgChartComponent: React.FC<OrgChartComponentProps> = ({data}) => {
  const svgRef = useRef<SVGSVGElement>(null);

  useEffect(() => {
    if (!svgRef.current) return;

    const width = 3000; // Increased width
    const height = 1000;
    const margin = {top: 50, right: 20, bottom: 50, left: 20};

    const svg = d3
      .select(svgRef.current)
      .attr('width', '100%')
      .attr('height', height)
      .style('background-color', 'white');

    svg.selectAll('*').remove();

    const g = svg.append('g').attr('transform', `translate(${margin.left},${margin.top})`);

    const root = d3.hierarchy<OrgChartNode>(data);

    const treeLayout = d3
      .tree<OrgChartNode>()
      .size([width - margin.left - margin.right, height - margin.top - margin.bottom])
      .separation((a, b) => (a.parent === b.parent ? 3 : 4)); // Increased separation

    const treeData = treeLayout(root);

    // Create links
    g.selectAll('.link')
      .data(treeData.links())
      .enter()
      .append('path')
      .attr('class', 'link')
      .attr(
        'd',
        d3
          .linkVertical<d3.HierarchyPointLink<OrgChartNode>, d3.HierarchyPointNode<OrgChartNode>>()
          .x((d) => d.x)
          .y((d) => d.y)
      )
      .attr('fill', 'none')
      .attr('stroke', '#ccc');

    // Create nodes
    const node = g
      .selectAll('.node')
      .data(treeData.descendants())
      .enter()
      .append('g')
      .attr('class', 'node')
      .attr('transform', (d) => `translate(${d.x},${d.y})`);

    // Add circles for nodes
    node
      .append('circle')
      .attr('r', 10)
      .attr('fill', (d) => {
        if (d.depth === 0) return '#333'; // Root node
        if (d.depth === 1) return departmentColors[d.data.name] || '#999'; // Department nodes
        return '#fff'; // Other nodes
      })
      .attr('stroke', (d) =>
        d.depth > 1 ? departmentColors[d.parent?.data.name || ''] || '#999' : '#333'
      )
      .attr('stroke-width', 2);

    // Add name labels
    node
      .append('text')
      .attr('dy', '.35em')
      .attr('y', (d) => (d.children ? -20 : 20))
      .style('text-anchor', 'middle')
      .text((d) => d.data.name)
      .style('font-weight', (d) => (d.depth <= 1 ? 'bold' : 'normal'))
      .call(wrap, 120); // Increased wrap width

    // Add title labels
    node
      .append('text')
      .attr('dy', '.35em')
      .attr('y', (d) => (d.children ? -40 : 40))
      .style('text-anchor', 'middle')
      .text((d) => d.data.title || '')
      .style('font-size', '0.8em')
      .style('fill', '#555')
      .call(wrap, 120); // Increased wrap width

    // Function to wrap text
    function wrap(
      selection: d3.Selection<
        SVGTextElement,
        d3.HierarchyPointNode<OrgChartNode>,
        SVGGElement,
        unknown
      >,
      width: number
    ) {
      selection.each(function (this: SVGTextElement) {
        const text = d3.select(this);
        const words = text.text().split(/\s+/).reverse();
        let word;
        let line: string[] = [];
        let lineNumber = 0;
        const lineHeight = 1.1; // ems
        const y = text.attr('y');
        const dy = parseFloat(text.attr('dy') || '0');
        let tspan = text
          .text(null)
          .append('tspan')
          .attr('x', 0)
          .attr('y', y)
          .attr('dy', dy + 'em');

        while ((word = words.pop())) {
          line.push(word);
          tspan.text(line.join(' '));
          if ((tspan.node()?.getComputedTextLength() || 0) > width) {
            line.pop();
            tspan.text(line.join(' '));
            line = [word];
            tspan = text
              .append('tspan')
              .attr('x', 0)
              .attr('y', y)
              .attr('dy', ++lineNumber * lineHeight + dy + 'em')
              .text(word);
          }
        }
      });
    }
  }, [data]);

  return <svg ref={svgRef}></svg>;
};
