// src/app/modules/apps/org-chart/_requests.ts

import {AxiosResponse} from 'axios';
import {initHttpClient} from '../../lib/_requests';

const API_URL = process.env.REACT_APP_API_URL;
const GET_ORG_CHART_DATA_URL = `${API_URL}/org/chart?${Date.now()}&`;

export interface OrgChartNode {
  name: string;
  title?: string;
  children?: OrgChartNode[];
}

export interface OrgChartResponse {
  data: OrgChartNode;
}

const handleAxiosError = (error: any) => {
  console.error('API Error:', error);
  if (error.response) {
    if (error.response.status === 500) {
      // Handle 500 error if needed
    }
    return error.response.data;
  } else if (error.request) {
    return error.request;
  } else {
    return error.message;
  }
};

export const getOrgChartData = (): Promise<OrgChartResponse> => {
  return initHttpClient()
    .get(GET_ORG_CHART_DATA_URL)
    .then((d: AxiosResponse<OrgChartResponse>) => d.data)
    .catch(handleAxiosError);
};
