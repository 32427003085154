// src/app/modules/apps/org-chart/DashboardWrapper.tsx

import React, {FC, useState, useEffect} from 'react';
import {useIntl} from 'react-intl';
import {PageTitle} from '../../../_metronic/layout/core';
import {OrgChartComponent} from './OrgChartComponent';
import {getOrgChartData, OrgChartResponse, OrgChartNode} from './_requests';

const DashboardPage: FC = () => {
  const [orgData, setOrgData] = useState<OrgChartNode | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchOrgData = async () => {
      try {
        setIsLoading(true);
        const response: OrgChartResponse = await getOrgChartData();
        setOrgData(response.data);
        setIsLoading(false);
      } catch (err) {
        setError('Failed to fetch organization data');
        setIsLoading(false);
        console.error('Error fetching org data:', err);
      }
    };

    fetchOrgData();
  }, []);

  if (isLoading) {
    return <div>Loading organization data...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'></div>
      <div className='card'>
        <div className='card-body p-lg-17'>
          <h1 className='mb-10'>Organizational Chart</h1>
          {orgData ? (
            <div style={{width: '100%', height: '800px', overflow: 'auto'}}>
              <OrgChartComponent data={orgData} />
            </div>
          ) : (
            <div>No organization data available</div>
          )}
        </div>
      </div>
    </>
  );
};

const DashboardWrapper: FC = () => {
  const intl = useIntl();
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  );
};

export {DashboardWrapper};
